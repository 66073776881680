export const ORDER_BASE_FRAGMENT = `
  fragment orderBaseFragment on Order {
    uid
    id
    totalPrice
    status
    currency
    creationTime
    paymentIntentId
    orderPackages {
      id
      uid
      quantity
      salesPackage {
       uid
       title
       price
       maximumQuantity
      }
    }
    exhibitor {
      uid
      name
    }
  }
`;
